/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import BanerContact from "components/BanerContact"
import Bestsellers from "components/Bestsellers"
import ConfigProvider from "context/config"

import { ContentSingle } from "page_components/wallpapers"

import { useProducts } from "context/products"
import { useConfig } from "context/config"

const SampleContent = ({ pageContext }) => {
  const { products } = useProducts()

  const formatted_products = products?.filter(item =>
    item?.productCategories?.nodes?.some(item => item?.slug === "probki")
  )

  return (
    <ConfigProvider
      variant="single-wallpaper"
      products={formatted_products}
      textures={pageContext?.data?.data?.acfProduct?.textures}
    >
      <SampleContentWrapper pageContext={pageContext} />
    </ConfigProvider>
  )
}

const SampleContentWrapper = ({ pageContext }) => {
  const { current_wallpaper, setCurrentWallpaper } = useConfig()

  return (
    <ContentSingle
      data={pageContext?.data?.data}
      sample
      current_wallpaper={current_wallpaper}
      setCurrentWallpaper={setCurrentWallpaper}
    />
  )
}

const Sample = ({ pageContext }) => {
  const isBrowser = typeof window !== "undefined"
  const fullSchema = JSON.parse(pageContext?.data?.data?.seo?.schema?.raw)

  useEffect(() => {
    if (isBrowser) {
      window.gtag("event", "view_item", {
        currency: "PLN",
        value: pageContext?.data?.data.price,
        items: [
          {
            item_id: pageContext?.data?.data?.databaseId,
            item_name: pageContext?.data?.data?.name,
            price: pageContext?.data?.data?.price,
            quantity: 1,
          },
        ],
      })
    }
  }, [])

  return (
    <Layout>
      <Breadcrumbs
        parent={{ slug: "/probki/", title: "Próbki" }}
        title={pageContext?.data?.data?.name}
        typeWide
      />
      <SampleContent pageContext={pageContext} />
      <Bestsellers />
      <BanerContact withMargin />
      {fullSchema && (
        <script type="application/ld+json">
          {JSON.stringify({ ...fullSchema }, null, 2)}
        </script>
      )}
    </Layout>
  )
}

export const Head = ({ pageContext }) => (
  <Seo
    title={
      pageContext?.data?.data?.seo?.title
        ? pageContext?.data?.data?.seo?.title
        : pageContext?.data?.data?.name
    }
    description={
      pageContext?.data?.data?.seo?.metaDesc &&
      pageContext?.data?.data?.seo?.metaDesc
    }
  />
)

export default Sample
